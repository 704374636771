import { graphql } from "gatsby"
import React from "react"
import Layout from "../layout"
import Img from "gatsby-image"
const ana = require("../../images/ana_circle.png")
// @ts-ignore
import styles from "./styles.module.scss"
import { ImageType } from "../PreviewItem"
import PreviewItem from "../PreviewItem"
import PostFooter from "./Footer"
import CustomHelmet from "../CustomHelmet"
import { VideoContainer } from "../Videos/VideoContainer"
// import SocialShare from "../SocialShare"

const getRelatedPosts = (posts: any, post) => {
  let postIndex = 0;
  const filteredPosts = posts.filter(p => Boolean(p.node.acf) && Boolean(p.node.acf.type) && Boolean(p.node.acf.show))
  filteredPosts.forEach((p, index) => {
    if (post.title === p.node.title) {
      postIndex = index;
      return
    }
  })
  return [1, 2, 3].map(index => filteredPosts[(postIndex + index)%(filteredPosts.length)]);
}

const Post = (props: any) => {
  const post = props.data.wordpressPost

  const entries = getRelatedPosts(props.data.allWordpressPost.edges, post)
  let meta = {} as any
  post.yoast_meta.forEach(item => {
    if (item.content) {
      meta[item.name || item.property] = item.content
      return
    } else {
      return
    }
  })

  const postStructuredData = `{
    "@type": "BlogPosting",
    "mainEntityOfPage": "https://desdemalta.com/${post.slug}/",
    "headline": "${post.title}",
    "name": "${post.title}",
    "description": "${meta.description}",
    "datePublished": "${post.date}",
    "dateModified": "${post.modified}",
    "image": "${post.featured_media.source_url}",
    "wordCount": "${post.fields.readingTime.words}",
    "author": {
			"@type": "Person",
			"name": "Ana Maria Cardona Ochoa",
			"image": "${ana}",
			"sameAs": "https://www.linkedin.com/in/anamariacardona/"
		}
  }`

  return (
    <Layout>
      <CustomHelmet
        title={post.title}
        description={meta.description}
        type={"article"}
        link={`/${post.slug}/`}
        {...post.featured_media ?  {image: post.featured_media.source_url} : {}}
        structuredData={postStructuredData}
      />
      <div className={styles.post}>
        {post.featured_media && post.featured_media &&
        <Img alt={post.featured_media.alt_text || "imagen del post"} className={styles.image}
             fluid={post.featured_media.localFile.childImageSharp.fluid} loading={'eager'}/>}
        <div className={styles.content}>
          <h1 dangerouslySetInnerHTML={{ __html: post.title }}/>
          <div dangerouslySetInnerHTML={{ __html: post.content }}/>
        </div>
        {post.acf && post.acf.youtube_id && <VideoContainer id={post.acf.youtube_id} description={""} title={""}/>}
        {/*<SocialShare path={post.slug}/>*/}
        <PostFooter/>
      </div>

      <div className={styles.postPreviewContainer}>
        {entries.map(e => {
          return (
            <PreviewItem
              link={"/" + e.node.slug}
              imageUrl={(e.node.featured_media && e.node.featured_media.localFile) ? e.node.featured_media.localFile.childImageSharp.fluid : ""}
              text={e.node.excerpt}
              title={e.node.title}
              key={e.node.slug + "/"}
              imageType={ImageType.squared}
              imageAlt={e.node.featured_media ? e.node.featured_media.alt_text : ""}
              categoryTag={e.node.acf.type}
            />
          )
        })}
      </div>
    </Layout>
  )

}

export default Post

export const pageQuery = graphql`
    query($id: String!) {
      allWordpressPost(sort: { fields: [date] }) {
        edges {
          node {
            title
            excerpt
            slug
            categories           
            featured_media {
              alt_text
              link
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 300, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            acf {
              type
              show
            }
          }
        }
      }
       wordpressPost(id: { eq: $id }) {
            title
            content
            slug
            modified
            date
            featured_media {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 350, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            acf {
              type
              show
              youtube_id
            }
            fields {
              readingTime {
                text
                minutes
                time
                words
              }
            }
            yoast_meta {
              property
              content
              name            
            }
        }
    }
`