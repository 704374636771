import React from "react"
import { CallToAction } from "../../CallToAction"

const styles = require("./styles.module.scss");

const PostFooter = (props: any) => {
  return (
    <div className={styles.postFooter}>
      <section>
        Quieres nuestra asistencia? Cuéntanos de ti y de tus planes de estudio. Dentro de 24 horas te contactaremos con la información y precios de las escuelas que más se ajustan a tu perfil.
      </section>
      <a href="https://mi.desdemalta.com/bienvenido/" target={"_blank"} rel="noopener noreferrer"><CallToAction
        className={styles.callToAction}>Formulario</CallToAction></a>
    </div>
  )
}

export default PostFooter;